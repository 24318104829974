<template>
  <ValidationObserver v-slot="{ invalid }" ref="validForm" tag="div">
    <v-simple-table>
      <template v-slot:default>
        <tbody>
          <template>
            <!-- 은행 -->
            <tr>
              <td>은행</td>
              <td>
                {{ isBnkAccount ? '39' : account.bank | bankCodeName }}
              </td>
            </tr>

            <!-- 계좌번호 -->
            <tr>
              <td>계좌번호</td>
              <td>{{ accountNo }}</td>
            </tr>

            <!-- 집금 계좌가 경남은행인 경우에만 표시되는 메뉴 시작 -->

            <template v-if="isBnkAccount">
              <!-- 생년월일 -->
              <ValidationProvider
                v-slot="{ errors }"
                name="birthdate_dash"
                rules="required|birthdate_dash"
                tag="tr"
              >
                <td>생년월일</td>
                <td v-if="birthDate">{{ birthDate }}</td>
                <td v-else class="py-2">
                  <v-text-field
                    v-model="inputBirthDate"
                    hide-details
                    outlined
                    dense
                    label="예) 1999-01-01"
                  />
                  <p class="mb-0 font-size--12 color--error">{{ errors[0] }}</p>
                </td>
              </ValidationProvider>

              <!-- ARS 인증 -->
              <tr>
                <td>ARS 인증</td>
                <td>
                  <template v-if="!nowArs">미인증</template>
                  <template v-else>
                    인증 {{ nowArs.isSuccess ? '완료' : '오류' }}

                    <!-- 변경 시각 -->
                    <p class="mb-0 font-size--13">
                      (변경시각
                      {{ nowArs.updateTime | moment('yyyy-MM-DD HH:mm:ss') }})
                    </p>

                    <!-- 오류 코드 및 메세지 -->
                    <p
                      v-if="!nowArs.isSuccess"
                      class="mb-0 font-size--13 color--error"
                    >
                      [{{ nowArs.retCode }}]
                      {{ nowArs.retMsg }}
                    </p>
                  </template>
                </td>
              </tr>

              <!-- 출금 이체 -->
              <tr>
                <td style="min-width: 90px">출금 이체</td>
                <td class="py-2">
                  <template v-if="!transferRegists || !nowTransferRegist">
                    미등록
                  </template>
                  <template v-else-if="nowTransferRegist">
                    등록
                    {{ nowTransferRegist.isSuccess ? '완료' : '오류' }}

                    <template v-if="nowTransferRegist.isLock">
                      <v-chip small color="error">락계좌</v-chip>
                    </template>

                    <!-- 오류 코드 및 메세지 -->
                    <p
                      v-if="!nowTransferRegist.isSuccess"
                      class="mb-0 font-size--13 color--error"
                    >
                      [{{ nowTransferRegist.retCode }}]
                      {{ nowTransferRegist.retMsg }}
                    </p>

                    <!-- 변경 시각 -->
                    <p class="mb-0 font-size--13">
                      (변경시각
                      {{
                        nowTransferRegist.updateTime
                          | moment('yyyy-MM-DD HH:mm:ss')
                      }})
                    </p>
                  </template>
                </td>
              </tr>

              <!-- 배치 출금 -->
              <tr>
                <td>배치 출금</td>
                <td>
                  <v-switch
                    dense
                    hide-details
                    class="mt-0"
                    v-model="useBatch"
                    :disabled="
                      !nowTransferRegist || !nowTransferRegist.isSuccess
                    "
                    @change="toggleBatch"
                  ></v-switch>
                  <p v-if="nowBatchesRegist" class="mb-0 font-size--13">
                    (변경시각
                    {{
                      nowBatchesRegist.updateTime
                        | moment('yyyy-MM-DD HH:mm:ss')
                    }})
                  </p>
                  <p
                    v-if="!nowTransferRegist || !nowTransferRegist.isSuccess"
                    class="mb-0 font-size--12 color--error"
                  >
                    출금 이체 등록이 완료되지 않아 배치 출금 설정이 불가합니다.
                  </p>
                </td>
              </tr>
            </template>

            <!-- 집금 계좌가 경남은행인 경우에만 표시되는 메뉴 끝 -->
          </template>
        </tbody>
      </template>
    </v-simple-table>

    <div class="mt-6" v-if="isBnkAccount">
      <!-- 등록된 출금이체 있는 경우 -->
      <template v-if="nowTransferRegist">
        <v-btn
          color="error"
          :loading="loading"
          :disabled="loading"
          @click="cancelTransferAndBatches"
          >출금 이체 해지</v-btn
        >
      </template>

      <!-- 등록된 출금이체 없는 경우 -->
      <template v-else>
        <v-card-actions class="pa-0">
          <v-btn
            color="primary"
            :loading="loading"
            :disabled="invalid || loading || !nowArs || !nowArs.isSuccess"
            @click="registTransfer(false)"
          >
            출금 이체 등록
          </v-btn>
          <v-btn
            color="warning"
            :loading="loading"
            :disabled="invalid || loading || !nowArs || !nowArs.isSuccess"
            @click="registTransfer(true)"
          >
            락계좌 등록
          </v-btn>
        </v-card-actions>
        <p
          v-if="!nowArs || !nowArs.isSuccess"
          class="mb-0 font-size--12 color--error"
        >
          ARS 인증이 완료되지 않아 출금 이체 등록이 불가합니다.
        </p>
      </template>
    </div>
  </ValidationObserver>
</template>

<script>
import { simpleClone } from '@/util/index.js'
import collectionAccountMixin from './collectionAccountMixin.js'

export default {
  props: {
    account: {
      type: Object,
      required: false,
    },
  },
  mixins: [collectionAccountMixin],
  data() {
    return {
      loading: false,
      useBatch: false,
      inputBirthDate: '',
    }
  },
  watch: {
    birthDate(v) {
      this.inputBirthDate = v
    },
    nowBatchesRegist(v) {
      if (v) {
        this.useBatch = v.useBatch
      }
    },
  },
  computed: {
    isBnkAccount() {
      return !this.account || this.account.bank === '39'
    },
    nowTransferRegist() {
      if (!this.transferRegists) return null

      const now = this.transferRegists.find((e) => {
        return e.applicationType === '1'
      })
      if (!now) return null

      return {
        isLock: now.isLock,
        updateTime: now.upDate || now.crDate,
        payrNo: now.payrNo,
        retCode: now.retCode,
        retMsg: now.retMsg,
        isSuccess: now.retCode === '000',
      }
    },
    nowBatchesRegist() {
      if (!this.nowTransferRegist || !this.batchesRegists) return null

      const now = this.batchesRegists.find((e) => {
        return e.payrNo === this.nowTransferRegist.payrNo
      })
      if (!now) return null

      return {
        useBatch: now.useYn === 'Y',
        updateTime: now.upDate || now.crDate,
      }
    },
    nowArs() {
      if (!this.arses || this.arses.length < 1) return null

      // 가장 최근건 return
      const now = simpleClone(this.arses).sort((a, b) =>
        this.$moment(a.crDate).isAfter(this.$moment(b.crDate)) ? -1 : 1
      )[0]

      return {
        updateTime: now.upDate || now.crDate,
        isSuccess: now.retCode === '0000',
        ...now,
      }
    },
  },
  async created() {
    await this.getData()
  },
  methods: {
    async getData() {
      await this.getBnkAccountarses()
      await Promise.all([this.getTransferRegists(), this.getBatchesRegists()])
    },

    async registTransfer(isLock) {
      if (this.loading) return

      try {
        this.loading = true
        const { isConfirmed } = await this.$swal.basic.confirm({
          title: '출금 이체 등록',
          text: `출금 이체 요청을 등록하시겠습니까? ${
            isLock ? '(락계좌)' : ''
          }`,
        })
        if (isConfirmed !== true) {
          return
        }

        const data = await this.$fundaApi.postBnkAccountTransferRegists({
          birthday: this.birthDate || this.inputBirthDate,
          bankAccount: this.accountNo,
          isLock,
        })

        if (data.result_code !== '000') {
          await this.$swal.basic.alert(data.result_message)
        }
      } catch (e) {
        await this.$swal.basic.alert(e?.data?.message)
      } finally {
        await this.getData()
        this.loading = false
      }
    },

    async cancelTransferAndBatches() {
      if (this.loading) return

      try {
        this.loading = true
        const { isConfirmed } = await this.$swal.basic.confirm({
          title: '출금 이체 해지',
          text: '출금 이체 등록을 해지하시겠습니까?',
        })
        if (isConfirmed !== true) {
          return
        }

        // 배치 해제 후 이체 해제
        if (this.nowBatchesRegist?.useBatch) {
          await this.$fundaApi.deleteBnkAccountBatchesRegists(this.accountNo)
          this.useBatch = false
        }
        await this.$fundaApi.deleteBnkAccountTransferRegists({
          birthday: this.birthDate || this.inputBirthDate,
          bankAccount: this.accountNo,
          isLock: this.nowTransferRegist.isLock,
        })
      } catch (e) {
        console.log(e)
        await this.$swal.basic.alert(e?.data?.message)
      } finally {
        await this.getData()
        this.loading = false
      }
    },
    async toggleBatch(value) {
      const { isConfirmed } = await this.$swal.basic.confirm({
        title: `배치 ${value ? '등록' : '해제'} 설정`,
        text: `배치 출금을 ${value ? '등록' : '해제'} 하시겠습니까?`,
      })
      if (isConfirmed !== true) {
        this.useBatch = !value
        return
      }

      if (!value) {
        await this.$fundaApi.deleteBnkAccountBatchesRegists(this.accountNo)
      } else {
        await this.$fundaApi.postBnkAccountBatchesRegists(this.accountNo)
      }

      await this.getData()
    },
  },
}
</script>

<style lang="scss" scoped></style>
